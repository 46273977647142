<template>
    <div class="mb-4">
        <div class="text-gray-400 title text-left duration-500 pc_show">我的设备</div>
        <div class="sm:mt-3">
            <div class="table w-full">
                <div class="table_title duration-500 flex items-center w-full">
                    <div
                        v-for="(item,index) in tableTitle"
                        :key="index"
                        class="h-full flex items-center duration-500"
                        style="width:33.33%"
                        :class="index == 0 ? 'justify-start' : index == 3 ? 'justify-end' : 'justify-center'"
                    >{{ item }}</div>
                </div>
                <div v-if="getEquipment.length > 0" class="w-full">
                    <ul class="w-full">
                        <li
                            class="flex w-full duration-500 items-center"
                            v-for="(item,index) in getEquipment"
                            :key="index"
                        >
                            <div
                                class="flex justify-start items-center li_item duration-500"
                            >{{ item.commodityName }}</div>
                            <div
                                class="flex justify-center items-center li_item duration-500"
                            >{{ item.factory }}</div>
                            <div
                                class="flex justify-center items-center li_item duration-500"
                            >{{ item.warrantyTime }}年</div>
                            <div
                                class="flex justify-end items-center li_item duration-500"
                            >{{ calculateDate(item.warrantyTime, item.warrantyDate) }}年</div>
                        </li>
                    </ul>
                </div>
                <div v-else class="w-full flex justify-center items-center py-4 bg-white null_box">
                    <div class="text-gray-400">暂无设备</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            tableTitle: ['设备名称', '设备出厂', '保修时间', '剩余时间'],
            tabIndex: 0,
        };
    },
    created() {
        this.$store.dispatch("aquireEquipment", this.$EventBus)
    },
    computed: {
        ...mapGetters(['getEquipment'])
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        // 计算剩余保修时间
        calculateDate(time, date) {
            let start = new Date(date)
            let current = new Date()
            let distance = current.getFullYear() - start.getFullYear()
            return time - distance
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}
.table_title {
    background: #e8e8e8;
}
.li_item {
    width: 25%;
    padding: 0 0.1875rem;
    word-wrap: break-word;
}
.list_dscs {
    height: 0.375rem;
    width: 0.375rem;
    background: black;
    border-radius: 50%;
    margin-right: 0.375rem;
}

@media screen and (max-width: 640px) {
    .log,
    .feedback {
        margin-top: 0.625rem;
    }
    .cate_title {
        margin-bottom: 0.3125rem;
    }
    .table {
        margin-top: 0.625rem;
        .table_title {
            // height: 2.1875rem;
            font-size: 0.8125rem;
            padding: 0.5rem 0.625rem;
        }
        ul {
            li {
                padding: 0.375rem 0.625rem;
                font-size: 0.75rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .pc_show {
        display: none;
    }
    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .table {
        margin-top: 0.625rem;
        .table_title {
            padding: 0.625rem 0.625rem;
        }
        ul {
            li {
                padding: 1.25rem 0.625rem;
                font-size: 0.875rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }
    .title {
        font-size: 1.875rem;
    }
}
</style>